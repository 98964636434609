<template>
    <div>
        <CCard class="shadow-sm m-2">
            <CCardBody>
                <CRow>
                    <CCol sm="1" col="2">
                        <img src="/img/icon/store.png" style="width: 32px; height:32px;" />
                    </CCol>
                    <CCol sm="9" col="8">
                        <select class="custom-select text-black" v-model="shop" disabled>
                            <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                                {{ shop.shopName }} - {{ shop.branchName }}
                            </option>
                        </select>

                    </CCol>
                    <div class="col-md-2 col-sm-2 col-2 text-right" v-if="loadingButton == true">
                        <CButton block color="light" @click="goback()">
                            <span><i class="fi fi-rr-arrow-left mr-2"
                                    style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
                        </CButton>
                    </div>
                    <div class="col-md-2 col-sm-2 text-right" v-else>
                        <CButton block color="success" disabled>
                            <CSpinner color="white" size="sm" />{{ $t('loading') }}
                        </CButton>
                    </div>
                </CRow>
            </CCardBody>
        </CCard>
        <CCol md="12" lg="12" class="mt-4">
            <CCard class="shadow-sm">
                <CCardBody>
                    <CAlert color="success" v-if="isSuccess === true">
                        {{ $t('createProductSuccess') }}
                    </CAlert>
                    <div class="row">
                        <div class="col-md-10 col-sm-9 col-9">
                            <h4 class="mt-2">
                                {{ $t('editOption') }}
                            </h4>       
                        </div>
                        <div class="col-md-2 col-sm-3 col-3 text-right">
                            <CButton block color="success font-weight-normal" v-if="loadingSaveButton === false"
                                @click="handlePopup()">
                                {{ $t('save') }}
                            </CButton>
                            <CButton block color="success font-weight-normal" v-else-if="loadingSaveButton === true">
                                <CSpinner color="white" size="sm" />{{ $t('save') }}
                            </CButton>
                        </div>
                    </div>
                    <div class="ml-5"> 
                            <CRow>
                                <CCol class="col-form-label" md="2" style=" margin-top: 2%;">{{
                                    $t('txtOption') }}</CCol>
                                <CCol style="margin-top: 2%;" md="8">
                                    <CInput v-if="validateName" v-model="name" :placeholder="$t('fillOption')"
                                        :is-valid="validator" :invalid-feedback="$t('fillOption')" />
                                    <CInput v-else v-model="name" :placeholder="$t('fillOption')" />
                                </CCol>
                            </CRow>
                            <CRow class="mt-2 mb-3">
                                <CCol class="col-form-label " md="2" style="">{{ $t('stockUnit') }}
                                </CCol>
                                <CCol md="8">
                                    <CInput v-model="price" :placeholder="$t('stockUnit')" />
                                </CCol>
                                <CCol md="2">
                                    <CButton block color="success" @click="showDetailMorePrice() ">{{ $t('morePrice') }}</CButton>
                                </CCol>
                            </CRow>

                        </div>
                    <hr />
                    <CRow>
                        <CCol sm="12" lg="12" md="12">
                            <div class="row">
                                <div class="col-md-10 col-sm-9 col-9">
                                    <h4>
                                        {{ $t('addSKUratio') }}
                                    </h4>
                                </div>
                                <div class="col-md-2 col-sm-3 col-3 text-right">
                                    <CButton block color="success font-weight-normal" @click="addProductBOM()">
                                        {{ $t('edit') }}
                                    </CButton>
                                </div>
                            </div>
                            <table class="table table-hover border mt-3" style="width: 100%;white-space:nowrap">

                                <thead>
                                    <th class="font-weight-normal text-dark">{{ $t('pluCode') }}</th>
                                    <th class="font-weight-normal text-dark" style="width:75%">{{ $t('productName') }}
                                    </th>
                                    <th style="width:3%" class="text-right font-weight-normal text-dark">
                                        {{ $t('SKURatio') }}
                                    </th>
                                </thead>
                                <tbody v-if="loadingButton == true">
                                    <tr v-for="(item, index) in this.BOM" :key="index" hover border striped>

                                        <td class="font-weight-normal text-dark">{{ item.ProductPLU.PLUCode }}</td>
                                        <td class="font-weight-normal text-dark">
                                            {{ item.ProductPLU.name }} ({{item.ProductPLU.unit.name }})
                                        </td>
                                        <td class="text-right font-weight-normal text-dark">
                                            {{ item.ratio }}/{{ item.ProductPLU.unit.name }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="loadingButton == false" class="text-center" width="100%">
                                <button class="btn" type="button" disabled style="color: darkcyan">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    {{ $t('loading') }}
                                </button>
                            </div>
                        </CCol>
                    </CRow>

                </CCardBody>
            </CCard>
        </CCol>
        <CModal  size="xl" :show.sync="showMorePrice" :title="$t('morePrice')" >
            <CCard class="mr-3 ml-4">
                <!-- <CCardBody> -->
                    <CRow  v-for="(item, index) in delivery" :key="index" class="mb-1 " >  
                        <CCol class="col-form-label " md="2" style="" v-if="item.channelId !== '101'" >
                            <img :src="item.img" class="rounded-circle"
                            style="border-radius: 3px; width: 35px;" />  {{item.name}}    
                        </CCol>
                        <CCol class="col-form-label " md="12" lg="12" style="" v-if="item.channelId === '101'" >
                            <img :src="item.img" class="rounded-circle"
                            style="border-radius: 3px; width: 35px;" />  {{item.name}} 
                        </CCol>

                        <div class="col-lg-5 col-12 text-left row" v-if="item.channelId !== '101'">
                            <label
                                class="col-sm-4 col-lg-4 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                {{ $t('difference') }}
                            </label>
                            <div class="col-sm-8 col-lg-8 col-8">
                                <input class="form-control text-danger"
                                    :value="getDifferent(item.deliveryOnDemand)"
                                    v-if="Number(getDifferent(item.deliveryOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.deliveryOnDemand)))"
                                    disabled />
                                    <input class="form-control  " :value="getDifferent(item.deliveryOnDemand)"
                                    disabled
                                    v-else-if="Number(getDifferent(item.deliveryOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.deliveryOnDemand))) === 0" />
                                    <input class="form-control text-success"
                                    :value="getDifferent(item.deliveryOnDemand)" disabled v-else />

                            </div>
                        </div>
                        <div class="col-lg-5 col text-left row " v-if="item.channelId !== '101'">
                            <label
                                class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                {{ $t('pricePackage') }}
                            </label>
                            <div class="col-sm-9 col-lg-9 col-8" >
                                <input type="number"  class="form-control  " @blur="updateAnabledByObtion(item,item.channelKey)"   v-model="item.deliveryOnDemand" />
                            </div>
                        </div> 
                        <div class="col-lg-12 " v-if="item.channelId === '101'">
                        
                            <CCallout color="primary" class="" style="padding: 0 0rem;">
                                        <div class="row mb-3">
                                            <div class="col-lg-2 col-12 ">
                                                <p class="mt-2 ml-3 text-left">{{ $t('deliveryOnDemand') }}</p>
                                            </div>
                                            <div class="col-lg-5 col-12 text-left row">
                                                <label
                                                    class="col-sm-4 col-lg-4 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('difference') }}
                                                </label>
                                                <div class="col-sm-8 col-lg-8 col-8">
                                                    <input class="form-control text-danger"
                                                        :value="getDifferent(item.deliveryOnDemand)"
                                                        v-if="Number(getDifferent(item.deliveryOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.deliveryOnDemand)))"
                                                        disabled />
                                                        <input class="form-control  " :value="getDifferent(item.deliveryOnDemand)"
                                                        disabled
                                                        v-else-if="Number(getDifferent(item.deliveryOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.deliveryOnDemand))) === 0" />
                                                        <input class="form-control text-success"
                                                        :value="getDifferent(item.deliveryOnDemand)" disabled v-else />

                                                </div>
                                            </div>
                                            <div class="col-lg-5 col-12 text-left row ">
                                                <label
                                                    class="col-sm-3 col-lg-3  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('pricePackage') }}
                                                </label>
                                                <div class="col-sm-9 col-lg-9 col-8" >
                                                    <input class="form-control " type="number" v-model="item.deliveryOnDemand" @blur="updateAnabledByObtion(item,item.channelKey)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">

                                            <div class="col-lg-2 col-12">
                                                <p class="mt-2 ml-3 text-left">{{ $t('deliveryScheduled') }}</p>
                                            </div>
                                            <div class="col-lg-5 col-12 text-left row ">
                                                <label
                                                    class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('difference') }}
                                                </label>
                                                <div class="col-sm-8 col-lg-8 col-8">
                                                    <input class="form-control text-danger"
                                                        :value="getDifferent(item.deliveryScheduled)"
                                                        v-if="Number(getDifferent(item.deliveryScheduled) < 0) || Number.isNaN(parseInt(getDifferent(item.deliveryScheduled)))"
                                                        disabled />
                                                        <input class="form-control  " :value="getDifferent(item.deliveryScheduled)"
                                                        disabled
                                                        v-else-if="Number(getDifferent(item.deliveryScheduled) == 0) || Number.isNaN(parseInt(getDifferent(item.deliveryScheduled))) === 0" />
                                                        <input class="form-control text-success"
                                                        :value="getDifferent(item.deliveryScheduled)" disabled v-else />
                                                </div>
                                            </div>

                                            <div class="col-lg-5 col-12 text-left row">
                                                <label
                                                    class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('pricePackage') }}
                                                </label>
                                            
                                                <div class="col-sm-9 col-lg-9 col-8">
                                                    <input class="form-control " type="number"  v-model="item.deliveryScheduled" @blur="updateAnabledByObtion(item,item.channelKey)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" row mb-3">
                                            <div class="col-lg-2 col-12">
                                                <p class="mt-2 ml-3 text-left">{{ $t('selfPickUpOnDemand') }}</p>
                                            </div>
                                            <div class="col-lg-5 col-12 text-left row ">
                                                <label
                                                    class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('difference') }}
                                                </label>
                                                <div class="col-sm-8 col-lg-8 col-8">
                                                    <input class="form-control text-danger"
                                                        :value="getDifferent(item.selfPickUpOnDemand)"
                                                        v-if="Number(getDifferent(item.selfPickUpOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.selfPickUpOnDemand)))"
                                                        disabled />
                                                        <input class="form-control  " :value="getDifferent(item.selfPickUpOnDemand)"
                                                        disabled
                                                        v-else-if="Number(getDifferent(item.selfPickUpOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.selfPickUpOnDemand))) === 0" />
                                                        <input class="form-control text-success"
                                                        :value="getDifferent(item.selfPickUpOnDemand)" disabled v-else />
                                                </div>
                                            </div>

                                            <div class="col-lg-5 col text-left row ">
                                                <label
                                                    class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('pricePackage') }}
                                                </label>
                                                <div class="col-sm-9 col-lg-9 col-8" >
                                                    <input class="form-control " type="number" v-model="item.selfPickUpOnDemand" @blur="updateAnabledByObtion(item,item.channelKey)" />

                                                </div>
                                
                                            </div>
                                        </div>
                                        <div class=" row mb-2">
                                            <div class="col-lg-2 col-12">
                                                <p class="mt-2 ml-3 text-left">{{ $t('dineInOnDemand') }}</p>
                                            </div>

                                            <div class="col-lg-5 col-12 text-left row ">
                                                <label
                                                    class="col-sm-4 col-lg-4 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('difference') }}
                                                </label>
                                                <div class="col-sm-8 col-lg-8 col-8">
                                                    <input class="form-control text-danger"
                                                        :value="getDifferent(item.dineInOnDemand)"
                                                        v-if="Number(getDifferent(item.dineInOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.dineInOnDemand)))"
                                                        disabled />
                                                        <input class="form-control  " :value="getDifferent(item.dineInOnDemand)"
                                                        disabled
                                                        v-else-if="Number(getDifferent(item.dineInOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.dineInOnDemand))) === 0" />
                                                        <input class="form-control text-success" type="number"
                                                        :value="getDifferent(item.dineInOnDemand)" disabled v-else />
                                                </div>
                                            </div>

                                            <div class="col-lg-5 col-12 text-right row ">
                                                <label
                                                    class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                    {{ $t('pricePackage') }}

                                                </label>
                                                <div class="col-sm-9 col-lg-9 col-8" >
                                                    <input class="form-control " v-model="item.dineInOnDemand" @blur="updateAnabledByObtion(item,item.channelKey)" />
                                                </div>
                                            </div>
                                        </div>
                            </CCallout>       
                        </div>    
                    </CRow>
                <!-- </CCardBody> -->
            </CCard>
            <template #footer>
                    <div class="w-100 d-flex justify-content-center">
                        <CButton style="width: 100%;margin-left: 1cap;" @click="showMorePrice = false" color="light">
                            <b>{{ $t("closed") }}</b>
                        </CButton>
                    </div>
            </template>
        </CModal>
    </div>
</template>

<script>
import util from '@/util/util'
import { mapGetters } from 'vuex'
import local from '@/services/local'
import permis from '@/util/permission'
import { CButton } from '@coreui/vue';

export default {
    components: {},
    data() {
        return {
            isSuccess: false,
            SKUName: '',
            keyword: '',
            data: [],
            dataSKUbytype: [],
            itemObjectId: [],
            validateShop: false,
            shop: this.$store.getters.shopObjectId,
            addlist: [],
            quantity: 1,
            countAddList: 0,
            loadingButton: true,
            price: '',
            name: '',
            itemId: '',
            itemObjectId: '',
            shop: this.$store.getters.shopObjectId,
            sortdata: 'name',
            loadingSaveButton: false,
            validateName: '',
            validatePrice: '',
            BOM: [],
            availableStatus : 'UNAVAILABLE',
            grabfood : {},
            isDeliveryActive : false,
            genObjectId : '',
            salesChannel: [],
            showMorePrice : false,
            delivery : []
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date']),
        isPermission() {
            let path = '/product'
            return permis.findPermissionRead('product', path)
        },
        isEditData() {
            let path = '/product'
            return permis.findPermissionEdit('product', path)
        },
        isDeleteData() {
            let path = '/product'
            return permis.findPermissionRemove('product', path)
        },
        uid() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            return shop.uid
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        objectId() {
            return this.$route.params.objectId
        },
        fields() {
            return [{ key: 'name', label: 'ชื่อสินค้า' }]
        },
        noImg() {
            
            return process.env.VUE_APP_NOIMAGE
        },
        
    },
    async created() {
        try {
            await this.getSalesChannelsSetting();
            await this.getProductOption();
            this.getBOM();
        } catch (error) {
            console.error('Error in created hook:', error);
        }

        this.shop = this.shops.find((i) => i.objectId === this.shop);
    },

    methods: {
        showDetailMorePrice(){
            this.showMorePrice = true
        },
        updateAnabledByObtion(delivery, key){

            if (typeof delivery.deliveryOnDemand !== 'number' || isNaN(delivery.deliveryOnDemand)) {
                 delivery.deliveryOnDemand = util.currencyStringToNumber(delivery.deliveryOnDemand);
            }
            if (typeof delivery.deliveryScheduled !== 'number' || isNaN(delivery.deliveryScheduled)) {
                delivery.deliveryScheduled = util.currencyStringToNumber(delivery.deliveryScheduled);
            }
            if (typeof delivery.selfPickUpOnDemand !== 'number' || isNaN(delivery.selfPickUpOnDemand)) {
                delivery.selfPickUpOnDemand = util.currencyStringToNumber(delivery.selfPickUpOnDemand);
            }
            if (typeof delivery.dineInOnDemand !== 'number' || isNaN(delivery.dineInOnDemand)) {
                delivery.dineInOnDemand = util.currencyStringToNumber(delivery.dineInOnDemand);
            }

            const data = {
                optionItem: {
                    objectId: this.itemObjectId,
                    id:this.id
                },
                channel : key,
                advancedPricing : {
                    id : delivery.id,
                    deliveryOnDemand: delivery.deliveryOnDemand,
                    deliveryScheduled: delivery.deliveryScheduled,
                    selfPickUpOnDemand: delivery.selfPickUpOnDemand,
                    dineInOnDemand: delivery.dineInOnDemand
                }   
            }       
 
            const headers = { shopObjectId: this.shopObjectId }
            
            local({
                method: 'PATCH',
                url: '/api/v3.0/option/item/update',
                data: data,
                headers: headers,
            })
            .then(
                setTimeout(() => {
                    this.loadingSaveButton = false
                }, 1000)
            ).catch((error) => {
                console.log(error)
                this.loadingSaveButton = false
            })

        },
        getSalesChannelsSetting() {
            let params = {
                shopObjectId: this.shopObjectId,
                type: 'ONLINE'
            }
            const headers = { 
                shopObjectId: this.shopObjectId 
            }
            local({
                url: "/api/v3.0/sellingchannel/establish",
                params: params,
                headers : headers,
                method: "get",
            }).then((res) => {
                const data = res.data?.data || [];             
                const channelKeyMap = {
                    101: "grabfood",
                    102: "lineman",
                    103: "foodpanda",
                    104: "shopeefood",
                    105: "robinhood",
                    106: "truefood",
                }; 

                this.salesChannel = data
                .map((item) => {
                    const channelKey = channelKeyMap[item.id] || "unknown";
                    return {
                        objectId: item.objectId || "",
                        gpPercent: item.gpPercent || null,
                        ID: item.id || "unknown",
                        name: item.name || "Unnamed Channel",
                        orderIndex: item.orderIndex || 0,
                        img: util.getDeliveryImg(item.id) || "/img/delivery/store.png", 
                        channelKey: channelKey,
                    };
                })  
                this.getProductOption()
            });
        },
        generateObjectId() {
            const uid = `${localStorage.getItem('shopsUid')}`;
            
            return local({
                method: 'get',
                url: `/api/v1.0/${uid}/objectId/generate`,
            })
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.objectId) {
                    return res.data.data.objectId; // Return the objectId
                } else {
                    throw new Error('Invalid response structure from the API.');
                }
            })
            .catch((error) => {
                console.error('Error generating objectId:', error);
                return null; // Return null in case of error
            });
        },

        getDifferent(item) {
            let difference = item - this.price;
            return difference;
        },
        goback() {
            this.$router.push('/product/option')
        },
        getProductOption(page = 1) {
            this.searchLoadingButton = false
            this.loadingButton = false
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            const objectId = this.objectId

            const params = { page: page, limit: 50, shopObjectId: shopObjectId, objectId: this.ObjectId }
            const headers = { shopObjectId: shopObjectId }
            local({
                url: '/api/V1.0/' + uid + '/option/item/' + objectId,
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                const data = res.data.data
                const delivery = []
              
                const channelKeyMap = {
                    101: "grabfood",
                    102: "lineman",
                    103: "foodpanda",
                    104: "shopeefood",
                    105: "robinhood",
                    106: "truefood",
                };
                let salesChannel = this.salesChannel

                let availableStatus = 'AVAILABLE'
                
                for (let j = 0; j < salesChannel.length; j++) {      
                    const channelId = salesChannel[j].ID;
                    const channelKey = channelKeyMap[channelId];
                    const name =  salesChannel[j].name

                    if(data[channelKey] === undefined){
                        data[channelKey] = {
                            id : util.generateObjectId(),
                            deliveryOnDemand: 0,
                            deliveryScheduled:0,
                            selfPickUpOnDemand: 0,
                            dineInOnDemand : 0
                        }
                    }else{
                        data[channelKey].deliveryOnDemand = data[channelKey].deliveryOnDemand ?? 0;
                        data[channelKey].deliveryScheduled = data[channelKey].deliveryScheduled ?? 0;
                        data[channelKey].selfPickUpOnDemand = data[channelKey].selfPickUpOnDemand ?? 0;
                        data[channelKey].dineInOnDemand = data[channelKey].dineInOnDemand ?? 0;
                    }   
                    delivery.push({
                        channelKey,
                        channelId,
                        name,
                        img: util.getDeliveryImg(channelId) || "/img/delivery/store.png", 
                        ...data[channelKey],
                        
                    });             
                }
                if(data.availableStatus !== undefined){
                    availableStatus =  data.availableStatus 
                }  
                this.data = data
                this.delivery = delivery
                this.name = data.name
                this.price = data.price
                this.itemObjectId = data.objectId
                this.id = data.id
                this.availableStatus = availableStatus
                this.searchLoadingButton = true
                this.loadingButton = true
            })
        },
        savedata() {
            this.loadingSaveButton = true
            this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            let data = []

            if (this.itemObjectId == '') {
                data = {
                    name: this.name,
                    price: parseFloat(this.price),
                    uid: uid,
                    shop: {
                        objectId: this.shop.objectId,
                        id: this.shop.id,
                        branchName: this.shop.branchName,
                    },
                }
            } else {
                data = {
                    id: this.itemId, //item id
                    objectId: this.itemObjectId, // objectid item
                    name: this.name,
                    price: parseFloat(this.price),
                    uid: uid,
                    shop: {
                        objectId: this.shop.objectId,
                        id: this.shop.id,
                        branchName: this.shop.branchName,
                    },
                }
            }

            const headers = { shopObjectId: shopObjectId }
            local({
                method: 'post',
                url: '/api/V1.0/' + uid + '/option/item/update',
                data: data,
                headers: headers,
            })
                .then(
                    setTimeout(() => {
                        // this.isSuccess = true
                        this.name = ''
                        this.loadingSaveButton = false
                        this.popupModal = false
                        this.addOptionModal = false
                        this.editOptionModal = false
                        this.$router.push('/product/option')

                    }, 1000)
                )
                .catch((error) => {
                    console.log(error)
                    this.loadingSaveButton = false
                })
        },
        validator(val) {
            return val ? val.length >= 1 : false
        },
        getBOM() {
            const uid = `${localStorage.getItem('shopsUid')}`
            const objectId = this.objectId

            let params = {
                objectId: this.objectId
            }
            const headers = {
                shopObjectId: this.shopObjectId,
            }
            local({
                url: '/api/V1.0/' + uid + '/option/item/product',
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                let dataBOM = res.data.data
                if (dataBOM == null) {
                    this.countBom = 0
                } else {
                    this.countBom = dataBOM.length  // Corrected this line
                }
                if (dataBOM == null) {
                    this.countBom = 0;
                } else {
                    // Check for deleted_at and filter out items accordingly
                    dataBOM = dataBOM.filter(e => !e.deleted_at);

                    this.countBom = dataBOM.length;
                }
                dataBOM.forEach((e) => {
                    this.BOM.push({
                        ProductPLU: e.productPLU,
                        id: e.productPLU.id,
                        name: e.productPLU.name,
                        objectId: e.productPLU.objectId,
                        unit: e.productPLU.unit,
                        ratio: e.ratio
                    })
                })

            })
        },

        validateadd() {
            if (this.name == '') {
                this.validateName = this.$i18n.t('fillOption')
            } else {
                this.validateName = ''
            }
            if (this.price == '') {
                this.validatePrice = this.$i18n.t('enterPrice')
            } else {
                this.validatePrice = ''
            }


        },
        addProductBOM() {
            this.isChannels = false
            if (this.objectSKUId == '') {
                this.validateadd()
            } else {
                let ObjectId = this.objectId
                this.$router.push({ name: 'AddOptionRatio', params: { ObjectId } })
            }
        },
        handlePopup() {
            if (this.name == '') {
                this.validateName = 'กรุณากรอกชื่อตัวเลือก'
            } else {
                this.validateName = ''
                this.savedata()
            }
        },
    },
}
</script>

<style>
.cardadd {
    min-height: 438px;
    max-height: 400px;
    border-color: #3abc98;
}
</style>